.not-feedback {
  padding: 110px 209px;
  background-color: #fff !important;
  margin-top: 24px;
  border-radius: 10px;

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    letter-spacing: -0.333333px;
    color: #3A4374;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    margin-bottom: 48px;
    color: #647196;
  }
}