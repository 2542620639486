.button {
  padding: 5px 16px;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  background-color: #f2f4ff !important;
  border-radius: 10px;
  color: #4661e6;
  outline: none;
  border: none;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #CFD7FF !important;
  }
}

.b-active {
  background-color: #4661e6 !important;
  color: #fff;
}