.roadmap {
  max-width: 290px;
  padding: 24px;
  border-radius: 10px;
  background-color: #ffffff !important;

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.25px;
    color: #3A4374;
  }

  &__link {
    font-weight: 700;
    font-size: 13px;
    line-height: 19px;
    text-decoration-line: underline !important;
    color: #8397F8;
  }

  &__list {
    padding-left: 24px;
  }

  &__item-text {
    margin-bottom: 0 !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #647196;
  }

  &__item-num {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    text-align: right;
    color: #647196;
  }
}

.roadmap__item {
  margin-bottom: 8px;
  position: relative;
  display: flex;
  justify-content: space-between;

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -20px;
    margin: auto;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    content: "";
  }

  &:nth-child(1)::before {
    background-color: #F49F85;
  }

  &:nth-child(2)::before {
    background-color: #AD1FEA;
  }

  &:nth-child(3)::before {
    background-color: #62BCFA;
  }
}