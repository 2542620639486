.new-feedback-main {
  position: relative;
  margin-top: 48px;
  padding: 52px 42px 40px;
  background-color: #fff;
  border-radius: 10px;


  &__img {
    position: absolute;
    top: -30px;
    left: 50px;
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: -0.333333px;
    color: #3A4374;
  }
}