.container {
  max-width: 1280px !important;
}

.container-2 {
  max-width: 800px !important;
}

.container-3 {
  max-width: 600px !important;
}